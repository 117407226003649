import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { KeycloakContext } from '../components/KeycloakProvider';
import { useNavigation } from '../constantsLanding';
import Button from './Button';
import MenuSvg from '../assets/svg/MenuSvg';
import { HamburgerMenu } from './design/Header';
import PandaIcon from '../icons/PandaIcon';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

const Header = () => {
  const pathname = useLocation();
  const navigation = useNavigation();
  const [openNavigation, setOpenNavigation] = useState(false);
  const { keycloak } = useContext(KeycloakContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Loading state to show progress indicator for login/register actions
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);

  const toggleNavigation = () => {
    if (openNavigation) {
      setOpenNavigation(false);
      enablePageScroll();
    } else {
      setOpenNavigation(true);
      disablePageScroll();
    }
  };

  const handleClick = () => {
    if (!openNavigation) return;
    enablePageScroll();
    setOpenNavigation(false);
  };

  const handleLogin = async () => {
    if (keycloak) {
      try {
        setIsLoadingLogin(true);
        await keycloak.login({ redirectUri: window.location.origin + '/dashboard' });
      } catch (error) {
        console.error("Login failed:", error);
        setIsLoadingLogin(false);
      }
    }
  };

  const handleRegister = async () => {
    if (keycloak) {
      try {
        setIsLoadingRegister(true);
        await keycloak.login({ redirectUri: window.location.origin + '/books' });
      } catch (error) {
        console.error("Registration failed:", error);
        setIsLoadingRegister(false);
      }
    }
  };

  const navigateLocal = (url) => {
    if (url === "/books") {
      handleRegister();
    } else if (url === "/dashboard") {
      handleLogin();
    } else {
      navigate(url);
    }
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full z-50 border-b border-n-6 lg:bg-n-8/90 lg:backdrop-blur-sm ${
        openNavigation ? 'bg-n-8' : 'bg-n-8/90 backdrop-blur-sm'
      }`}
    >
      <div className="flex items-center px-5 lg:px-7.5 xl:px-10 max-lg:py-4">
        <a className="block w-[12rem] xl:mr-8 flex items-center space-x-2" href="/">
          <div className="logo-container flex items-center space-x-1">
            <PandaIcon />
            <span className="text-blue">Happ</span>
            <span className="text-black">Read</span>
          </div>
        </a>

        <nav
          className={`${
            openNavigation ? 'flex' : 'hidden'
          } fixed top-[5rem] left-0 right-0 bottom-0 bg-n-8 lg:static lg:flex lg:mx-auto lg:bg-transparent`}
        >
          <div className="relative z-2 flex flex-col items-center justify-center m-auto lg:flex-row">
            {navigation.map((item) => (
              <a
                key={item.id}
                href={item.onlyMobile ? '#' : item.url}
                onClick={(e) => {
                  if (item.onlyMobile) {
                    e.preventDefault();
                    navigateLocal(item.url);
                  } else {
                  handleClick();
                    e.preventDefault(); // Prevent default navigation
                    const targetElement = document.querySelector(item.url); // Find the target element
                    if (targetElement) {
                      const yOffset = -70; // Adjust this to your desired offset
                      const y = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
                      window.scrollTo({ top: y, behavior: 'smooth' }); // Scroll smoothly
                    }
                  }
                }}
                className={`block relative font-code text-2xl uppercase text-black transition-colors hover:text-color-1 ${
                  item.onlyMobile ? 'lg:hidden' : ''
                } px-6 py-6 md:py-8 lg:-mr-0.25 lg:text-xs lg:font-semibold ${
                  item.url === pathname.hash ? 'z-2 lg:text-black' : 'lg:text-black/50'
                } lg:leading-5 lg:hover:text-black xl:px-12`}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <span style={{ color: ((item.url === '/books' && isLoadingRegister) || (item.url === '/dashboard' && isLoadingLogin)) ? 'grey' : 'inherit' }}>
                    {item.title}
                  </span>
                    <span style={{ marginTop: '5px', visibility: ((item.url === '/books' && isLoadingRegister) || (item.url === '/dashboard' && isLoadingLogin)) ? 'visible' : 'collapse' }}>
                      <CircularProgress size={20} />
                    </span>
                </div>
              </a>
            ))}
          </div>

          <HamburgerMenu />
        </nav>

        <a
          onClick={() => !isLoadingRegister && handleRegister()}
          style={{ cursor: 'pointer' }}
          className={`button hidden mr-8 text-black-50 transition-colors hover:text-n-1 lg:block ${
            isLoadingRegister ? 'pointer-events-none opacity-50' : ''
          }`}
        >
          {isLoadingRegister ? (
            <div className="flex items-center space-x-2">
              <CircularProgress size={20} />
              <span>{t('newAccount')}</span>
            </div>
          ) : (
            t('newAccount')
          )}
        </a>
        <Button
          className="hidden lg:flex text-black min-w-[167.5px]" // Adjust min-width to your needs
          onClick={() => !isLoadingLogin && handleLogin()}
          disabled={isLoadingLogin}
        >
          {isLoadingLogin ? (
            <div className="relative flex items-center justify-center">
              <CircularProgress 
                size={20} 
                style={{ color: "#00b2ff", position: "absolute" }} 
              />
              <span style={{ visibility: "hidden" }}>{t('signIn')}</span>
            </div>
          ) : (
            t('signIn')
          )}
        </Button>


        <Button className="ml-auto lg:hidden" px="px-3" onClick={toggleNavigation}>
          <MenuSvg openNavigation={openNavigation} />
        </Button>
      </div>
    </div>
  );
};

export default Header;
